import React, { useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import _findIndex from 'lodash/findIndex';
import queryString from 'query-string';
import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';

import type {
  WCmsZolaRegistryView,
  WRegistryCollectionItemView,
  WRegistryView,
} from '@zola/svc-web-api-ts-client';

import { mapFontColorToBgOverlayWithOpacity } from '~/components/publicWebsiteV2/util/mappers';
import { isDarkColor } from '~/pages/publicStyleUtils/utils.styles';
import { trackEvent } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { trackProductAdded } from '@zola-helpers/client/dist/es/tracking/eCommerce/productEvents';
import { getItemOrigin } from '~/util/cartUtil';
import { negative } from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';
import { getGiftNotificationTitle } from '@zola/component-public-registry/src/utils/registry';

import ZolaLogo from '@zola/zola-ui/src/components/ZolaLogo';

import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';

import { useAppSelector } from '~/reducers/useAppSelector';
import { useAppDispatch } from '~/reducers/useAppDispatch';
import { getRegistryCollections } from '~/selectors/registryCollections/registryCollectionsSelectors';
import { getRegistryUrl } from '~/selectors/registry/registrySelectors';
import { addItem, getCartSize } from '~/actions/CartActions';
import { hideModal, showModal } from '~/actions/ModalActions';

import { PageAnimationWrapper } from '~/components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import featureFlags from '~/util/featureFlags';
import { getStripePublishableKey } from '~/util/stripe';
import { CheckoutRequest, submitCheckout } from '~/api/checkout';
import { fetchRegistryByCollectionItemId, fetchRegistryBySlug } from '~/actions/RegistryActions';
import { createOrUpdatePaymentIntent, getCartByStore, removeCartItems } from '~/api/cart';
import {
  addDigitalGreetingCardToCart,
  getDigitalGreetingCard,
  getDigitalGreetingCardTemplate,
} from '~/api/digitalGreetingCard';
import { isBaby } from '~/util/baby';
import type { QuickCheckoutModalProps } from '@zola/zola-ui/src/components/Checkout/QuickCheckoutModal';
import ZolaRegistryFilterBar from './ZolaRegistryFilterBar';
import {
  ZolaRegistryHeader,
  ZolaRegistryDetails,
  ZolaRegistryFooter,
  mapValuesToRegistryTileSerializedStyles,
  RegistryTileGrid,
  ConditionalRender,
  RegistryTileGridSP,
  ViewAllContainer,
} from './ZolaRegistry.styles';

/**
 * Used by the quick checkout modal to determine what to do when the modal is closed
 */
let quickCheckoutPurchaseSuccess = false;

const RegistryTile = dynamic(
  () => import('@zola/component-public-registry').then((module) => module.RegistryTile),
  {
    loading: () => <div>loading</div>,
    ssr: false,
  }
);

export type AddToCartRequest = {
  skuId?: string;
  collectionItemId?: string;
  itemOrigin?: string;
  contribution?: number;
  quantity?: number;
};

export type ZolaRegistrySP = {
  isGuestViewing: boolean;
  zolaRegistry?: WCmsZolaRegistryView;
  isSinglePage?: boolean;
  babySlug?: string;
};

const ZolaRegistry: React.FC<ZolaRegistrySP> = ({
  isGuestViewing,
  zolaRegistry,
  isSinglePage,
  babySlug,
}) => {
  const {
    state: {
      components: {
        cmsEntityComponentBodyFontValues,
        globalAccentColor,
        CmsEntityBodyLink,
        backgroundColors,
        ThemedButton,
      },
      wedding,
      inPreview,
    },
  } = useWebsiteThemeContext();

  const registryCollections = useAppSelector(getRegistryCollections);
  const registryUrl = useAppSelector(getRegistryUrl);
  const dispatch = useAppDispatch();

  const isDarkMode = isDarkColor(backgroundColors.content);
  const bodyFontColor = cmsEntityComponentBodyFontValues.color;
  const bodyFontFamily = cmsEntityComponentBodyFontValues.fontFamily;
  const bgOverlayColor = mapFontColorToBgOverlayWithOpacity(bodyFontColor);
  // TODO: CONVERT TO INLINE STYLE
  const StyledRegistryTile = styled(RegistryTile)`
    ${mapValuesToRegistryTileSerializedStyles(
      {
        backgroundOverlayColor: bgOverlayColor,
        textColor: bodyFontColor,
        textFont: bodyFontFamily,
        buttonColor: globalAccentColor,
        backgroundColor: backgroundColors.content,
      },
      isSinglePage
    )}
  `;

  const { slug } = wedding || {};

  const [registry, setRegistry] = useState<WRegistryView>();

  useEffect(() => {
    const registrySlug = slug || babySlug;
    if (zolaRegistry && registrySlug) {
      dispatch(fetchRegistryBySlug(registrySlug))
        .then((response) => {
          setRegistry(response);
        })
        .catch(() => null);
    }
  }, [dispatch, babySlug, slug, zolaRegistry]);

  useEffectOnce(() => {
    if (typeof window !== 'undefined') {
      if (window.location.hash !== '') {
        // if we're returning from Registry, bring user to previous location
        const scrollY = Number(window.location.hash.substring(1));
        setTimeout(() => {
          window.scrollTo(0, scrollY);
        }, 1000);
      }

      if (isGuestViewing) {
        trackEvent('Registry Viewed', {
          location: 'REGISTRY_ON_WEDDING_WEBSITE',
          registry_id: zolaRegistry?.registry_object_id,
        });
      }
    }
  });

  // TODO: ADD MAX QUANTITY LOGIC
  const addToCart = (collectionItem: WRegistryCollectionItemView, quantity: number) => {
    if (inPreview) return;
    const addToCartRequest: AddToCartRequest = {
      skuId: collectionItem.sku_object_id,
      collectionItemId: collectionItem.object_id,
      itemOrigin: getItemOrigin(),
    };
    if (collectionItem.contributions?.group_gift) {
      addToCartRequest.contribution = quantity;
    } else {
      addToCartRequest.quantity = quantity;
    }
    dispatch(addItem(addToCartRequest))
      .then((res) => {
        if (res) {
          const { cartId } = res.data;
          const {
            product_id,
            sku_object_id,
            name,
            brand,
            price,
            images,
            product_look_id,
          } = collectionItem;
          trackProductAdded({
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ cart_id: any; registry_id: str...
            cart_id: cartId,
            registry_id: zolaRegistry?.registry_object_id,
            product_id,
            sku: sku_object_id,
            name,
            brand: brand?.name,
            price,
            quantity,
            image_url: images?.[0]?.medium,
            variant: product_look_id,
            location: 'WEDDING_WEBSITE',
            section: 'QUICK_ADD',
          });
          if (typeof window !== 'undefined') {
            window.dispatchEvent(new CustomEvent('OPEN_MODAL_EVENT', { detail: 'CART' }));
            dispatch(getCartSize()).catch(() => undefined);
          }
        }
      })
      .catch(() => undefined);
  };

  /**
   * Confirm an external item purchase or Venmo contribution
   */
  const showRegistryProductPurchasedModal = useCallback(
    ({ purchased, isCashFund }: { purchased: boolean; isCashFund: boolean }) => {
      let title = getGiftNotificationTitle(registry, isCashFund);
      let hideCheck = false;
      if (!purchased) {
        title = 'Thank you for letting us know.';
        hideCheck = true;
      }
      dispatch(
        showModal(
          'REGISTRY_PRODUCT_PURCHASED_MODAL',
          {
            title,
            hideCheck,
            onContinue: () => {
              dispatch(hideModal());
            },
          },
          {
            className: 'registry-product-purchased-modal',
            size: 'sm',
            bottomModalOnMobile: true,
          }
        )
      );
    },
    [dispatch, registry]
  );

  /**
   * Purchase a digital greeting card
   */
  const showQuickCheckoutModal = useCallback(
    ({
      stripeClientSecret,
      amountCents,
      productName,
      paymentIntentId,
      orderId,
      giftGiverName,
      giftGiverEmail,
      message,
      isCashFund,
    }: {
      stripeClientSecret: string;
      amountCents: number;
      productName: string;
      paymentIntentId: string;
      orderId: string;
      giftGiverName: string;
      giftGiverEmail: string;
      message: string;
      isCashFund: boolean;
    }) => {
      quickCheckoutPurchaseSuccess = false;
      dispatch(
        showModal(
          'QUICK_CHECKOUT_MODAL',
          {
            isPaymentIntentUpgrade: featureFlags.get('paymentIntentQuickCheckoutUpgrade'),
            stripePublishableKey: getStripePublishableKey(),
            stripeClientSecret,
            amountCents,
            productName,
            onSuccess: (result) => {
              const { confirmationToken, handleStripeNextAction } = result || {};
              quickCheckoutPurchaseSuccess = true;
              const parts = giftGiverName.trim().split(/\s+/);
              const firstName = parts.shift() || '';
              const lastName = parts.join(' ');
              const request: CheckoutRequest = {
                paymentIntentId,
                orderEmail: giftGiverEmail,
                billingInfo: {
                  firstName,
                  lastName,
                },
                shippingInfo: {
                  firstName,
                  lastName,
                  email: giftGiverEmail,
                },
                giftMessage: message,
                dgcGroupOrderId: orderId,
                stripeConfirmationTokenId: confirmationToken?.id,
              };
              submitCheckout(request)
                .then((checkoutResponse) => {
                  if (
                    !checkoutResponse.successful &&
                    checkoutResponse?.errorCategory?.reason === 'requires-additional-action'
                  ) {
                    handleStripeNextAction?.();
                    return;
                  }
                  if (!checkoutResponse.successful && checkoutResponse.userMessage) {
                    dispatch(negative({ headline: checkoutResponse.userMessage }));
                  }
                  showRegistryProductPurchasedModal({ purchased: true, isCashFund });
                })
                .catch((errorResponse) => {
                  const headline =
                    errorResponse.response?.error?.message ||
                    'There was a problem processing your order.';
                  dispatch(negative({ headline }));
                });
            },
            onHideModal: () => {
              if (quickCheckoutPurchaseSuccess) {
                showRegistryProductPurchasedModal({ purchased: true, isCashFund });
              } else {
                // Remove the digital greeting cart from the cart
                getCartByStore('WEDDING_REGISTRY')
                  .then((cart) => {
                    const itemIds = cart.items?.map((item) => item.id as string) || [];
                    return removeCartItems(itemIds, 'WEDDING_REGISTRY');
                  })
                  .catch(() => null);
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                showDigitalGreetingCardModal({
                  orderId,
                  giftGiverName,
                  giftGiverEmail,
                  isCashFund,
                });
              }
            },
          } as QuickCheckoutModalProps,
          {
            className: 'quick-checkout-modal',
            size: 'sm',
            bottomModalOnMobile: true,
          }
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  /**
   * Select a digital greeting card and add a gift message
   */
  const showDigitalGreetingCardModal = useCallback(
    ({
      orderId,
      giftGiverName,
      giftGiverEmail,
      isCashFund,
    }: {
      orderId: string;
      giftGiverName: string;
      giftGiverEmail: string;
      isCashFund: boolean;
    }) => {
      getDigitalGreetingCard()
        .then((response) => {
          dispatch(
            showModal(
              'DIGITAL_GREETING_CARD_MODAL',
              {
                digitalGreetingCard: response,
                loadDigitalGreetingCardTemplate: getDigitalGreetingCardTemplate,
                onSkip: () => {
                  showRegistryProductPurchasedModal({ purchased: true, isCashFund });
                },
                onContinue: (skuId: string, priceCents: number, message: string) => {
                  addDigitalGreetingCardToCart(skuId, message)
                    .then((cart) => {
                      return createOrUpdatePaymentIntent(cart.cart_id as string);
                    })
                    .then((paymentIntent) => {
                      showQuickCheckoutModal({
                        stripeClientSecret: paymentIntent.clientSecret as string,
                        amountCents: priceCents,
                        productName: 'Personalized e-card',
                        paymentIntentId: paymentIntent.paymentIntentId as string,
                        orderId,
                        giftGiverName,
                        giftGiverEmail,
                        message,
                        isCashFund,
                      });
                    })
                    .catch(() => {
                      dispatch(
                        negative({
                          headline:
                            'There was a problem adding the e-card to your cart. Please try again.',
                        })
                      );
                    });
                },
              },
              {
                className: 'digital-greeting-card-modal',
                size: 'xl',
                bottomModalOnMobile: true,
              }
            )
          );
        })
        .catch(() => {
          showRegistryProductPurchasedModal({ purchased: true, isCashFund });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  /**
   * Show product details for a registry item
   *
   * This is the start of the digital greeting card flow, which shows the following modals in
   * succession after an external item purchase or Venmo contribution:
   * - RegistryItemModal
   * - DGCModal
   * - QuickCheckoutModal
   * - RegistryProductPurchasedModal
   *
   * The code to manage the flow is duplicated between `web-registry` and `web-wedding`, because
   * each app has its own Redux store, modal management and API calls.
   */
  const showProductDetails = useCallback(
    (collectionItem: WRegistryCollectionItemView, isDefaultGiftCard: boolean, position: number) => {
      if (inPreview) return;
      const isCashFund = Boolean(collectionItem.cash_fund);
      const isExternal =
        collectionItem.type === (('EXTERNAL' as unknown) as WRegistryCollectionItemView.TypeEnum);
      const fetchRegistryWithAddress = isExternal
        ? dispatch(fetchRegistryByCollectionItemId(collectionItem.object_id))
        : Promise.resolve(registry);
      fetchRegistryWithAddress
        .then((registryWithAddress) => {
          dispatch(
            showModal(
              'REGISTRY_ITEM',
              {
                registry: registryWithAddress,
                collectionItem,
                isDefaultGiftCard,
                position,
                onExternalPurchaseFlowComplete: (
                  step: string,
                  orderId?: string,
                  giftGiverName?: string,
                  giftGiverEmail?: string
                ) => {
                  const isMissingOrderInfo = !orderId || !giftGiverName || !giftGiverEmail;
                  if (
                    !featureFlags.get('enableDGCFlow') ||
                    isBaby() ||
                    step === 'DIDNT_BUY' ||
                    isMissingOrderInfo
                  ) {
                    showRegistryProductPurchasedModal({
                      purchased: step !== 'DIDNT_BUY',
                      isCashFund,
                    });
                  } else {
                    showDigitalGreetingCardModal({
                      orderId,
                      giftGiverName,
                      giftGiverEmail,
                      isCashFund,
                    });
                  }
                },
                onVenmoFlowComplete: (
                  contributed: boolean,
                  orderId?: string,
                  giftGiverName?: string,
                  giftGiverEmail?: string
                ) => {
                  const isMissingOrderInfo = !orderId || !giftGiverName || !giftGiverEmail;
                  if (
                    !featureFlags.get('enableDGCFlow') ||
                    isBaby() ||
                    !contributed ||
                    isMissingOrderInfo
                  ) {
                    showRegistryProductPurchasedModal({ purchased: contributed, isCashFund });
                  } else {
                    showDigitalGreetingCardModal({
                      orderId,
                      giftGiverName,
                      giftGiverEmail,
                      isCashFund,
                    });
                  }
                },
                enableDGCFlow: true,
              },
              {
                className: 'registry-item-modal',
                size: 'xl',
                bottomModalOnMobile: true,
              }
            )
          );
        })
        .catch(() => {
          dispatch(
            negative({
              headline: 'There was a problem getting additional registry info. Please try again.',
            })
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, inPreview, registry]
  );

  const determineRegistryUrl = (): string => {
    if (registryUrl) {
      return registryUrl;
    }
    if (slug) {
      return `/registry/${slug}${!isGuestViewing ? `?preview=true` : ''}`;
    }
    return '/wedding-registry';
  };

  const handleClickViewAll = (): void => {
    if (inPreview) return;
    if (typeof window !== 'undefined') {
      window.open(determineRegistryUrl(), '_blank', 'noopener, noreferrer')?.focus();
    }
  };

  const { defaultGiftCard, selectedCollectionObject, defaultCollection, otherCollections } =
    registryCollections || {};

  const isFilteredByCollection = selectedCollectionObject && (defaultCollection || []).length === 0;
  const selectedCollection = isFilteredByCollection
    ? otherCollections?.[0]?.registry_collection_items
    : defaultCollection;

  const collection = selectedCollection ? [...selectedCollection] : [];

  useEffect(() => {
    if (registry) {
      const { collectionItemId, splitOrderId, token, reservedQuantity } = queryString.parse(
        window.location.search
      );
      const isRedirectFromEmail = splitOrderId && token && reservedQuantity;
      const externalItem = collection.find(
        (collectionItem) => collectionItem.object_id === collectionItemId
      );
      if (isRedirectFromEmail && externalItem) {
        showProductDetails(externalItem, false, 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registry]);

  // For SP only show the first 6 items in registry
  if (isSinglePage && collection && collection.length > 8) {
    collection.splice(selectedCollection && defaultGiftCard ? 7 : 8, collection.length - 1);
  }

  if (selectedCollection && defaultGiftCard) {
    // add gift card to end of purchasable items
    let index = _findIndex(
      collection,
      (item) =>
        item?.contributions?.show_as_fulfilled ||
        item?.contributions?.show_as_reserved ||
        !item?.actions?.add_to_cart
    );
    // if all available, place it in the end of the collection
    if (index < 0) {
      index = collection.length;
    }
    defaultGiftCard.button_cta = 'Choose amount';
    defaultGiftCard.detail_items = [];
    defaultGiftCard.contributions = {};
    collection.splice(index, 0, defaultGiftCard);
  }

  const renderRegistryFooter = () => (
    <ZolaRegistryFooter
      style={{
        backgroundColor: bgOverlayColor,
        fontFamily: bodyFontFamily,
        color: `#${bodyFontColor}`,
      }}
    >
      Need help finding the perfect gift from Zola Registry? Reach out to us at{' '}
      <CmsEntityBodyLink href="mailto:support@zola.com">support@zola.com</CmsEntityBodyLink> or{' '}
      <CmsEntityBodyLink href="tel:4086579652">1 (408) 657-ZOLA (9652)</CmsEntityBodyLink>.
    </ZolaRegistryFooter>
  );

  return isSinglePage ? (
    <div data-testid="ZolaRegistrySP">
      <PageAnimationWrapper index={1}>
        {collection && collection.length > 0 ? (
          <>
            <RegistryTileGridSP>
              {collection.map((collectionItem: WRegistryCollectionItemView, index: number) => {
                const isDefaultGiftCard =
                  collectionItem.type ===
                  (('GIFT_CARD' as unknown) as WRegistryCollectionItemView.TypeEnum);

                return (
                  <StyledRegistryTile
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onClick: () => void; collectionItemDetail:...
                    onClick={() => {
                      showProductDetails(collectionItem, isDefaultGiftCard, index);
                    }}
                    collectionItemDetail={collectionItem}
                    onAddToCart={addToCart}
                    key={`${collectionItem.object_id}-${index}`} // || collectionItem.id} <-- might be applicable to different registry item types in preview?
                    registryKey={slug}
                    isDefaultGiftCard={isDefaultGiftCard}
                    id={collectionItem.object_id} // || collectionItem.id}
                    compactButton={false}
                    disableBootstrapStyling
                    useV2Tags
                  />
                );
              })}
            </RegistryTileGridSP>
            <ViewAllContainer>
              <ThemedButton onClick={handleClickViewAll}>View all gifts</ThemedButton>
            </ViewAllContainer>
          </>
        ) : (
          renderRegistryFooter()
        )}
      </PageAnimationWrapper>
    </div>
  ) : (
    <div>
      <ZolaRegistryHeader>
        <ZolaLogo color={isDarkMode ? 'white' : ''} />
        <ZolaRegistryDetails style={{ color: `#${bodyFontColor}`, fontFamily: bodyFontFamily }}>
          <ConditionalRender
            as={CmsEntityBodyLink}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; as: StyledComponent<Anch...
            href="/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            About Zola
          </ConditionalRender>
          <CmsEntityBodyLink href="tel:4086579652">1-408-657-ZOLA</CmsEntityBodyLink>
          <ConditionalRender>Free Shipping Every Day</ConditionalRender>
        </ZolaRegistryDetails>
      </ZolaRegistryHeader>
      <ZolaRegistryFilterBar collection={collection} isDarkMode={isDarkMode} />
      {collection && collection.length > 0 && (
        <RegistryTileGrid>
          {collection.map((collectionItem: WRegistryCollectionItemView, index: number) => {
            const isDefaultGiftCard =
              collectionItem.type ===
              (('GIFT_CARD' as unknown) as WRegistryCollectionItemView.TypeEnum);

            return (
              <StyledRegistryTile
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onClick: () => void; collectionItemDetail:...
                onClick={() => {
                  showProductDetails(collectionItem, isDefaultGiftCard, index);
                }}
                collectionItemDetail={collectionItem}
                onAddToCart={addToCart}
                key={`${collectionItem.object_id}-${index}`} // || collectionItem.id} <-- might be applicable to different registry item types in preview?
                registryKey={slug}
                isDefaultGiftCard={isDefaultGiftCard}
                id={collectionItem.object_id} // || collectionItem.id}
                compactButton={false}
                disableBootstrapStyling
                useV2Tags
              />
            );
          })}
        </RegistryTileGrid>
      )}
      {renderRegistryFooter()}
    </div>
  );
};

export default ZolaRegistry;
