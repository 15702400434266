import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
// types
import type { WAwsFacet, WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';
import _isEqual from 'lodash/isEqual';
// redux
import { useAppSelector } from '~/reducers/useAppSelector';
import { useAppDispatch } from '~/reducers/useAppDispatch';
import { getRegistryCollections } from '~/selectors/registryCollections/registryCollectionsSelectors';
import {
  resetFacetValues,
  searchV2,
  selectCollectionById,
  toggleFacetValueAction,
  updateSort,
  replaceFacetValues,
} from '~/actions/RegistryCollectionActions';
// components
import {
  GenericDropdownCheckboxList,
  GenericToggle,
} from '~/components/publicWebsiteV2/util/genericFormElements.styles';
// hooks
import useMediaQuery from '~/lib/hooks/useMediaQuery';
import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';

import capitalizeFirst from '@zola-helpers/client/dist/es/transformers/capitalizeFirst';
// styles
import {
  FacetRow,
  FilterGroup,
  CategoriesDropdown,
  SortDropdown,
  StyledGenericFinalFormDropdown,
} from './ZolaRegistryFilterBar.styles';
// helpers
import {
  SORT_OPTIONS,
  DEFAULT_COLLECTION_OPTION,
  COLLECTIONS_DROPDOWN_FACET,
  SORT_DROPDOWN_FACET,
} from './helpers';
import FilterPillContainer from './FilterPillContainer';
import ZolaRegistryFilterBarMobile from './ZolaRegistryFilterBarMobile';

const ZolaRegistryFilterBar = ({
  collection,
  isDarkMode,
}: {
  collection: WRegistryCollectionItemView[];
  isDarkMode: boolean;
}): JSX.Element => {
  const registryCollections = useAppSelector(getRegistryCollections);
  const dispatch = useAppDispatch();
  const isMobileScreenSize = useMediaQuery(768);

  const {
    state: { inPreview },
  } = useWebsiteThemeContext();

  const {
    collectionList,
    facets = [],
    selectedCollectionObject,
    selectedFacetValues,
    sort,
  } = registryCollections;
  const collectionOptions = [
    DEFAULT_COLLECTION_OPTION,
    ...(collectionList || []).map((c) => ({ value: c.object_id, label: c.title })),
  ];

  const onSelectCollection = (collectionDropdownValue: string) => {
    const collectionObjectId =
      collectionDropdownValue === collectionOptions[0].value ? '' : collectionDropdownValue;
    // onSelect called on all click outside; regardless of whether value is different
    if (selectedCollectionObject?.value !== collectionObjectId) {
      dispatch(selectCollectionById(collectionObjectId));
      dispatch(resetFacetValues());
      dispatch(searchV2()).catch(() => undefined);
    }
  };

  const onUpdateSort = (value: string) => {
    if (sort !== value) {
      const isDefaultSortValue = value === SORT_OPTIONS[0].value || !value;
      dispatch(updateSort(isDefaultSortValue ? '' : value));
      dispatch(searchV2()).catch(() => undefined);
    }
  };

  const onToggleFacet = (facetKey: string, facetValue: string) => {
    dispatch(toggleFacetValueAction(facetKey, facetValue));
    dispatch(searchV2()).catch(() => undefined);
  };

  const onUpdateFacet = (facetKey: string, facetValues: string[]) => {
    if (selectedFacetValues[facetKey] !== facetValues) {
      dispatch(replaceFacetValues(facetKey, facetValues));
      dispatch(searchV2()).catch(() => undefined);
    }
  };

  const onResetFacets = () => {
    dispatch(resetFacetValues());
    dispatch(searchV2()).catch(() => undefined);
  };

  if (isMobileScreenSize || inPreview === 'MOBILE') {
    return (
      <ZolaRegistryFilterBarMobile
        collection={collection}
        collectionOptions={collectionOptions}
        selectedCollectionObject={selectedCollectionObject}
        onSelectCollection={onSelectCollection}
        onUpdateSort={onUpdateSort}
        sort={sort}
        onResetFacets={onResetFacets}
        facets={facets}
        onToggleFacet={onToggleFacet}
        selectedFacetValues={selectedFacetValues}
      />
    );
  }

  const renderDropdownCheckboxListFacet = (f: WAwsFacet) => {
    const { key, label, options } = f;
    if (!key || !label || !options) return null;
    return (
      <FieldArray name={key}>
        {({ ...props }) => (
          <GenericDropdownCheckboxList
            id={key}
            label={label}
            //
            options={
              options
                ?.map(({ value, label: optionLabel, result_count }) => ({
                  value,
                  label: `${optionLabel} (${result_count})`,
                }))
                .filter((o) => o.value) as { value: string; label: string }[]
            }
            withCaret
            includeCountInLabel
            isDarkMode={false}
            {...props}
          />
        )}
      </FieldArray>
    );
  };

  const renderDropdownFacet = (f: WAwsFacet, overrideLabel?: string) => {
    const { key, label, options } = f;
    if (!key) return null;
    return (
      <StyledGenericFinalFormDropdown
        id={key}
        alignment="horizontal"
        name={key}
        placeholder={overrideLabel || label}
        options={(options || []).map(({ value, label: optionLabel }) => ({
          value,
          label: capitalizeFirst(optionLabel as string),
        }))}
        onSelect={(v: string) => {
          if (v) {
            onUpdateFacet(key, [v]);
          } else {
            onUpdateFacet(key, []);
          }
        }}
        isDarkMode={isDarkMode}
        nullable
      />
    );
  };

  const shippingFacet = facets.find((f) => f.key === 'SHIPPING_OPTION');
  const twoDayShippingOption = shippingFacet?.options?.find((o) => o.value === 'TWO_DAY');
  const availabilityFacet = facets.find((f) => f.key === 'AVAILABILITY');
  const priceFacet = facets.find((f) => f.key === 'PRICE');
  const storeFacet = facets.find((f) => f.key === 'STORE');

  return (
    <Form
      onSubmit={() => undefined}
      mutators={{ ...arrayMutators }}
      initialValues={{
        CATEGORIES: collectionOptions[0].value,
        SORT_BY: SORT_OPTIONS[0].value,
        ...selectedFacetValues,
        AVAILABILITY: selectedFacetValues?.AVAILABILITY?.[0] || null,
        STORE: selectedFacetValues?.STORE?.[0],
      }}
      render={(formProps) => {
        const { handleSubmit } = formProps;
        return (
          <form
            onSubmit={(e) => {
              handleSubmit(e)?.catch(() => undefined);
            }}
          >
            <FacetRow>
              <FilterGroup>
                <CategoriesDropdown
                  name={COLLECTIONS_DROPDOWN_FACET.key}
                  options={collectionOptions}
                  onSelect={onSelectCollection}
                  isDarkMode={isDarkMode}
                />
                {priceFacet && renderDropdownCheckboxListFacet(priceFacet)}
                {availabilityFacet && renderDropdownFacet(availabilityFacet, 'All availability')}
                {storeFacet &&
                  (storeFacet.options || []).length > 1 &&
                  renderDropdownFacet(storeFacet, 'All stores')}
                {shippingFacet &&
                  shippingFacet.key &&
                  twoDayShippingOption &&
                  twoDayShippingOption.value && (
                    <GenericToggle
                      id="registry-two-day-shipping-toggle"
                      label={twoDayShippingOption?.label}
                      onChange={(v) => {
                        onToggleFacet(
                          shippingFacet.key as string,
                          v ? (twoDayShippingOption.value as string) : ''
                        );
                      }}
                      value={
                        (selectedFacetValues?.[shippingFacet.key] || []).indexOf(
                          twoDayShippingOption.value
                        ) > -1
                      }
                      withIcons
                      isDarkMode={isDarkMode}
                    />
                  )}
              </FilterGroup>
              <SortDropdown
                name={SORT_DROPDOWN_FACET.key}
                options={SORT_OPTIONS}
                placeholder={SORT_DROPDOWN_FACET.label}
                nullable
                onSelect={onUpdateSort}
                alignment="horizontal"
                defaultValue="Featured"
                isDarkMode={isDarkMode}
              />
            </FacetRow>
            <FilterPillContainer onClose={onToggleFacet} onCloseAll={onResetFacets} />
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }): void => {
                // Only handling the price facet
                if (!_isEqual(values.PRICE, selectedFacetValues.PRICE)) {
                  onUpdateFacet('PRICE', values.PRICE);
                }
              }}
            />
          </form>
        );
      }}
    />
  );
};

export default ZolaRegistryFilterBar;
