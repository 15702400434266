import fetch from 'isomorphic-fetch';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import {
  HAS_SHIPPING_ADDRESS,
  RECEIVE_REGISTRY,
  REGISTRY_PUBLISHED,
  REQUEST_REGISTRY,
} from './types/RegistryActionTypes';
import { getRegistryAccountId } from '../selectors/user/userSelectors';
import { getRegistryKey } from '../selectors/registry/registrySelectors';

// TODO: MOVE THIS AND USE FOR ALL REQUESTS!!!!
function handleErrors(response) {
  if (!response.ok) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
  return response;
}

function requestRegistry() {
  return {
    type: REQUEST_REGISTRY,
  };
}

export function receiveRegistry(json) {
  return {
    type: RECEIVE_REGISTRY,
    payload: json,
  };
}

export function registryPublished() {
  return {
    type: REGISTRY_PUBLISHED,
  };
}

export function hasShippingAddress() {
  return {
    type: HAS_SHIPPING_ADDRESS,
  };
}

export function updateRegistryPublish(enable) {
  return (dispatch) =>
    fetch('/web-api/v1/registry/publish', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ enable }),
    }).then(() => dispatch(registryPublished()));
}

export function fetchRegistry() {
  return (dispatch) => {
    dispatch(requestRegistry());
    return fetch('/web-api/v1/registry', {
      credentials: 'same-origin',
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveRegistry(json));
        return json;
      });
  };
}

export function fetchRegistryBySlug(slug) {
  return (dispatch) => {
    dispatch(requestRegistry());
    return fetch(`/web-api/v1/registry/slug/${slug}`, {
      credentials: 'same-origin',
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveRegistry(json));
        return json;
      });
  };
}

export function fetchRegistryByCollectionItemId(collectionItemId) {
  return (dispatch) => {
    dispatch(requestRegistry());
    return fetch(`/web-api/v1/registry-collection/${collectionItemId}/registry`, {
      credentials: 'same-origin',
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveRegistry(json));
        return json;
      });
  };
}

export function setShippingAddress(shippingAddress, validate) {
  return (dispatch) =>
    fetch('/api/v0/registry/shipping-address', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ shippingAddress, validate }),
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then(() => dispatch(hasShippingAddress()));
}

export function updateShippingAddress(addressData, validate) {
  return (dispatch) =>
    ApiService.post('/website-nav/web-api/v1/address/update_shipping_address', {
      addressData,
      validate,
    }).then(() => dispatch(hasShippingAddress()));
}

export function updateGroupGiftGoals() {
  return () =>
    fetch('/web-api/v1/registry/update_group_gift_goals', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then(handleErrors);
}

export const maybeGetRegistry = () => (dispatch, getState) => {
  const state = getState();
  const registryId = getRegistryAccountId(state);
  const hasFetchedRegistry = getRegistryKey(state);

  if (!registryId || hasFetchedRegistry) return false;

  return dispatch(fetchRegistry());
};
