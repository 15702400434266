import { css } from '@emotion/react';
import { mapModeThemeToCheckboxStyles } from '~/components/publicWebsiteV2/util/genericFormElements.styles';
import { mapValuesToButtonStyles } from '~/components/publicWebsiteV2/util/mappers/mapButtonStyles';
import { COLORS, FONT, SPACING } from '@zola/zola-ui/src/styles/emotion';
import type { SerializedStyles } from '@emotion/react';

export const mapValuesToMobileFilterOverrides = (
  fontFamily: string,
  accentColor: string
): SerializedStyles => {
  return css`
    .public-registry-facets__sticky-row--top {
      height: 44px;
      .public-registry-facets__title {
        font-size: ${FONT.FONT_SIZE_REGULAR};
      }
      .zola-ui-icon-close::before {
        position: absolute;
        right: 0;
        top: 8px;
      }
    }
    .public-registry-facets__sticky-row--bottom {
      padding: ${SPACING.S10};
    }
    // zola-ui overrides
    .v2-button.primary-button {
      ${mapValuesToButtonStyles(fontFamily, accentColor)}
    }
    .v2-button.secondary-button {
      color: ${COLORS.EMPEROR};
    }
    ${mapModeThemeToCheckboxStyles(false)}
    .public-registry-facets__options-section .checkbox-list__section.zola-ui {
      &.render-checkbox-field input[type='checkbox']:checked + label .checkbox-icon-wrapper {
        background-color: ${COLORS.EMPEROR};
        border-color: ${COLORS.EMPEROR};
      }
      &.render-radio-field input[type='radio']:checked:not([disabled]) + label:before {
        background-color: ${COLORS.EMPEROR};
        border-color: ${COLORS.EMPEROR};
      }
    }
  `;
};
