import _get from 'lodash/get';
import type { WRegistryView } from '@zola/svc-web-api-ts-client';
import type { RootState } from '~/reducers/index';

export const selectRegistry = (state: RootState): WRegistryView => _get(state, 'registry');

export const getRegistryAvatarUrl = (state: RootState): string =>
  _get(state, 'registry.profile_image', '');

export const getRegistryIsPublic = (state: RootState): boolean =>
  _get(state, 'registry.is_public', false);

export const getHasShippingAddress = (state: RootState): boolean =>
  _get(state, 'registry.has_shipping_address', false);

export const getRegistryKey = (state: RootState): string => _get(state, 'registry.key', '');

export const getRegistryUrl = (state: RootState): string => {
  const registryKey = getRegistryKey(state);

  if (!registryKey) return '';

  return `/registry/${registryKey}`;
};

export const selectOtherRegistries = (state: RootState) =>
  _get(state, 'registryBulkImport.otherRegistries', []);

export default {
  getRegistryAvatarUrl,
  getRegistryIsPublic,
  getRegistryKey,
  getRegistryPreviewUrl: getRegistryUrl,
  selectOtherRegistries,
};
