import ApiService from '@zola-helpers/client/dist/es/http/api';
import type {
  AddToCartRequest,
  WCardTemplateView,
  WCartView,
  WGreetingCardProductView,
} from '@zola/svc-web-api-ts-client';
import { TemplatePage } from '@zola/zola-ui/src/components/PaperSamplesFlow/types';

export const getDigitalGreetingCard = () => {
  return ApiService.get<WGreetingCardProductView>('/web-registry-api/v1/greeting-card/product');
};

export const getDigitalGreetingCardTemplate = (skuObjectId: string) => {
  return ApiService.get<WCardTemplateView>(
    `/web-registry-api/v2/card-template/greeting-card/${skuObjectId}/template`
  ).then((response) => {
    return response.pages?.[0] as TemplatePage;
  });
};

export const addDigitalGreetingCardToCart = (skuId: string, message: string) => {
  const request: Omit<AddToCartRequest, 'user_id' | 'ip_address' | 'user_agent'> = {
    sku_id: skuId,
    item_origin: 'WEDDING_REGISTRY',
    gift_message: message,
    clear_existing_cart: true,
  };
  return ApiService.post<
    WCartView,
    Omit<AddToCartRequest, 'user_id' | 'ip_address' | 'user_agent'>
  >('/web-registry-api/v1/greeting-card/add-to-cart', request);
};
