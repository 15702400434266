import styled from '@emotion/styled';
import { MEDIA_QUERY, SPACING } from '@zola/zola-ui/src/styles/emotion';

export const ExternalRegistrySection = styled.div`
  margin-top: ${SPACING.XXL};

  ${MEDIA_QUERY.MOBILE} {
    margin-top: ${SPACING.XL};
  }
`;

export const Container = styled.div`
  padding-bottom: 120px;
`;

export const RegistryMenu = styled.ul`
  align-items: center;
  border-bottom: 1px solid;
  border-top: 1px solid;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  font-size: 20px;
  justify-content: center;
  padding: ${SPACING.S10} 0;
`;

export const RegistryMenuItem = styled.li<{ selected?: boolean }>`
  display: inline-block;
  padding: ${SPACING.S10};
  &:hover {
    cursor: pointer;
  }
  text-align: center;
  font-weight: ${({ selected }) => (selected ? '600' : '400')};
`;

export const RegistryContent = styled.div`
  min-height: 170px;
`;

export const EmptyStateMessage = styled.p`
  text-align: center;
`;
