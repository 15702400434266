import ApiService from '@zola-helpers/client/dist/es/http/api';
import type { ConvertWebApiDeclaredToActual } from '@zola-helpers/client/dist/es/@types/svc-web-api';
import type { Submit, WCheckoutResponseView } from '@zola/svc-web-api-ts-client';
import type { CamelCasedPropertiesDeep } from 'type-fest';

type WithoutBaseRequest<T> = Omit<T, 'user_agent' | 'ip_address' | 'user_id'>;
export type CheckoutRequest = CamelCasedPropertiesDeep<
  Omit<
    WithoutBaseRequest<Submit>,
    'guest' | 'impact_radius_click_id' | 'order_key' | 'post_auth_click_id'
  >
>;

export const submitCheckout = (body: CheckoutRequest) => {
  return ApiService.post<
    CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<WCheckoutResponseView>>,
    CheckoutRequest
  >(`/web-registry-api/v1/checkout`, body);
};
