const AMAZON_TAG_WEDDINGS = 'zola0c-20';

const manualRedirect = (url: string) => {
  window.open(url, '_blank', 'noopener, noreferrer')?.focus();
};

export const handleAffiliateLink = (url: string) => {
  if (typeof window !== 'undefined') {
    const normalizedUrl = url.toLowerCase();

    // Amazon
    if (normalizedUrl.includes('amazon.com')) {
      const hasExistingParams = normalizedUrl.includes('?');
      const appendMethod = hasExistingParams ? '&' : '?';
      const amazonTag = `tag=${AMAZON_TAG_WEDDINGS}`;
      manualRedirect(`${url}${appendMethod}${amazonTag}`);
      return;
    }

    // Hilton - Fix for book/reservation links not working with Souvrn
    if (normalizedUrl.includes('hilton.com') && normalizedUrl.includes('book/reservation')) {
      manualRedirect(url);
      return;
    }

    // Souvrn - Catch-all
    try {
      window.vglnk.open(url, '_blank');
    } catch (err) {
      manualRedirect(url);
    }
  }
};
