import ApiService from '@zola-helpers/client/dist/es/http/api';
import type { RootState } from '~/reducers/index';
import type { AppDispatch, AppThunk } from '~/reducers/useAppDispatch';
import type {
  WRegistryCollectionSearchView,
  WProductView,
  WRegistryCollectionItemView,
  RegistryCollectionItemsSearchRequest,
} from '@zola/svc-web-api-ts-client';
import * as ActionTypes from './types/RegistryCollectionActionTypes';

export function reset(): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.RESET,
  };
}

function requestSearch(): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.REQUEST_SEARCH,
  };
}

export function receiveSearch(
  json: WRegistryCollectionSearchView
): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.RECEIVE_SEARCH,
    payload: json,
  };
}

function requestDefaultGiftCard(): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.REQUEST_DEFAULT_GIFT_CARD,
  };
}

export function receivedDefaultGiftCard(
  json?: WProductView
): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.RECEIVE_DEFAULT_GIFT_CARD,
    payload: json,
  };
}

export function toggleFacetValueAction(
  facetKey: string,
  valueKey: string
): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.TOGGLE_FACET_VALUE,
    payload: {
      facetKey,
      valueKey,
    },
  };
}

export function replaceFacetValues(
  facetKey: string,
  facetValues: string[]
): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.REPLACE_FACET_VALUES,
    payload: {
      facetKey,
      facetValues,
    },
  };
}

function requestCollectionItemByCollectionId(): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID,
  };
}

function receivedCollectionItemByCollectionId(
  data: WRegistryCollectionItemView
): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID,
    payload: data,
  };
}

export function resetFacetValues(facetKey?: string): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.RESET_FACET_VALUES,
    payload: {
      facetKey,
    },
  };
}

export function updateSort(sort: string): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.UPDATE_SORT,
    payload: {
      sort,
    },
  };
}

export function selectCollection(collectionObject: {
  label: string;
  value: string;
}): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.SELECT_COLLECTION,
    payload: {
      collectionObject,
    },
  };
}
export function selectCollectionById(
  collectionObjectId: string
): ActionTypes.RegistryCollectionActionTypes {
  return {
    type: ActionTypes.SELECT_COLLECTION_BY_ID,
    payload: {
      collectionObjectId,
    },
  };
}

export function fetchDefaultGiftCard(): AppThunk<
  Promise<ActionTypes.RegistryCollectionActionTypes>
> {
  return (dispatch: AppDispatch) => {
    dispatch(requestDefaultGiftCard());
    return ApiService.get<WProductView>(
      '/web-api/v1/registry-collection/zola_gift_card'
    ).then((json) => dispatch(receivedDefaultGiftCard(json)));
  };
}

export function fetchRegistryCollectionItem(
  collectionItemId: string
): AppThunk<Promise<ActionTypes.RegistryCollectionActionTypes>> {
  return (dispatch: AppDispatch) => {
    dispatch(requestCollectionItemByCollectionId());
    return fetch(`/web-registry-api/v1/registryCollection/item/${collectionItemId}`, {
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((json) => dispatch(receivedCollectionItemByCollectionId(json.data)));
  };
}

export function search(groupedByCollection = true, showSingleOptionFacets = false) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(requestSearch());

    const sort =
      getState().registryCollections.sort !== '' &&
      getState().registryCollections.sort !== 'FEATURED'
        ? getState().registryCollections.sort
        : undefined;

    const collectionObjectId =
      getState().registryCollections.selectedCollectionObject?.value !== ''
        ? getState().registryCollections.selectedCollectionObject?.value
        : undefined;
    const request = {
      registry_key: getState().wedding.wedding.slug || getState().publicWebsite.wedding.slug,
      filters: getState().registryCollections.selectedFacetValues,
      sort,
      collection_object_id: collectionObjectId,
      flattened_view: !!sort || (!groupedByCollection && !collectionObjectId),
      grouped_by_collection: groupedByCollection,
      show_single_option_facets: showSingleOptionFacets,
    };

    return ApiService.post<WRegistryCollectionSearchView>(
      '/web-api/v1/registry-collection/search',
      request
    ).then((json) => dispatch(receiveSearch(json)));
  };
}

export function searchV2(): AppThunk<Promise<ActionTypes.RegistryCollectionActionTypes>> {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(requestSearch());
    const state = getState();
    const collectionObjectId =
      state.registryCollections.selectedCollectionObject?.value || undefined;
    const request: Omit<RegistryCollectionItemsSearchRequest, 'sort'> & { sort?: string } = {
      registry_key: state.wedding.wedding.slug || state.publicWebsite.wedding.slug,
      filters: state.registryCollections.selectedFacetValues,
      collection_object_id: collectionObjectId,
      flattened_view: true,
      grouped_by_collection: false,
      show_single_option_facets: true,
    };
    if (state.registryCollections.sort !== '' && state.registryCollections.sort !== 'FEATURED') {
      request.sort = state.registryCollections.sort;
    }
    return ApiService.post<WRegistryCollectionSearchView>(
      '/web-api/v1/registry-collection/search',
      request
    ).then((json) => dispatch(receiveSearch(json)));
  };
}

export function toggleFacetValue(
  facetKey: string,
  valueKey: string,
  groupByCollection = true,
  showSingleOptionFacets = false
): AppThunk<void> {
  return (dispatch: AppDispatch) => {
    dispatch(toggleFacetValueAction(facetKey, valueKey));
    dispatch(search(groupByCollection, showSingleOptionFacets)).catch(() => undefined);
  };
}
