import styled from '@emotion/styled';
import { mapValuesToButtonStyles } from '~/components/publicWebsiteV2/util/mappers/mapButtonStyles';
import { css, SerializedStyles } from '@emotion/react';
import { SPACING, COLORS, FONT } from '@zola/zola-ui/src/styles/emotion';
import { isDarkColor } from '~/pages/publicStyleUtils/utils.styles';

export const ZolaRegistryHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACING.LG};
  margin-top: 47px;
`;

export const ZolaRegistryDetails = styled.div`
  display: flex;
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_MOBILE} {
    > a {
      margin-right: ${SPACING.S24};
    }
  }
`;

export const ZolaRegistryFooter = styled.div`
  padding: ${SPACING.MD} 100px;
  border-radius: 20px;
  text-align: center;
  a {
    color: inherit;
    font-weight: 600;
  }
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    padding-left: ${SPACING.S24};
    padding-right: ${SPACING.S24};
  }
`;

const productNameLineHeight = 24;
type RegistryTileStyleProps = {
  backgroundColor?: string;
  textColor?: string;
  textFont?: string;
  buttonColor: string;
  backgroundOverlayColor?: string;
};
// TODO: CONVERT TO INLINE STYLES BY PASSING STYLE OBJECTS TO COMPONENT-PUBLIC-REGISTRY
export const mapValuesToRegistryTileSerializedStyles = (
  {
    backgroundOverlayColor,
    textColor,
    textFont = 'Proxima Nova',
    buttonColor,
    backgroundColor,
  }: RegistryTileStyleProps,
  isSinglePage?: boolean
): SerializedStyles => {
  return css`
    border-radius: 16px;
    width: 100%;
    min-width: 0;
    padding: 0;
    height: ${isSinglePage ? 'unset' : '555px'};

    &.registry-collection-item-tile_container {
      height: 100%;
      .shop-tile {
        border-radius: 16px;
        margin-bottom: 0 !important;
        background-color: #${backgroundColor};
        font-family: '${textFont}';
        position: relative;
        box-shadow: none;
        &:hover {
          box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
        }

        .registry-collection-item-tile_entity-details {
          background-color: ${backgroundOverlayColor};
          padding: ${SPACING.MD} ${SPACING.SM};
          ${isSinglePage ? 'display: flex; min-height: 200px;' : ''}

          .price-wrapper {
            .price {
              color: #${textColor};
              font-weight: ${FONT.WEIGHT.SEMI_BOLD};
            }
            .ref-price {
              color: #${textColor};
            }
          }
        }
        .item-price_container .item-benefit-message .message.subtle {
          color: #${textColor};
        }
        [data-bv-show=inline_rating] .bv_main_container .bv_text {
          color: #${textColor} !important;
        }
        .register-item-name p {
          color: #${textColor};
          font-family: '${textFont}';
        }
        .register-item-name_brand-name {
          margin-top: 0;
          font-size: ${FONT.SIZE.SMALLER};
          line-height: 22px;
        }
        .register-item-name_entity-name {
          font-weight: 600;
          font-size: ${FONT.SIZE.SMALL};
          line-height: ${productNameLineHeight}px;
          margin-bottom: ${SPACING.MD};
          max-height: ${productNameLineHeight * 2}px;
        }
        p.item-price_percentage {
          color: #${textColor};
        }
        .still-needs {
          color: #${textColor};
        }

        .registry-item-tile_actionable {
          background-color: ${backgroundOverlayColor};
          padding: 0px 12px 14px 12px;
          border-radius: 0 0 16px 16px;
          min-height: 93px;
        }

        .registry-actions {
          height: 44px;
          .registry-actions_item {
            padding: 0;
            .registry-actions_qty {
              width: 42px;
              height: 100%;
              border-width: 2px;
              -moz-appearance: textfield;

              &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
            .v2-button {
              ${mapValuesToButtonStyles(textFont, buttonColor)}
              width: 100%;
              > div {
                width: unset;
              }
            }
            // prevent long button text from overflowing container
            > span {
              max-width: 100%;
            }
          }
          color: #${textColor};
        }
        .registry-item-contribution {
          justify-content: flex-start;
          margin-left: -5px;
          color: #${textColor};
          font-family: '${textFont}';
          a {
            font-family: '${textFont}';
          }
        }
      }
      .image-container {
        .registry-item-thumbnail {
          img {
            border-radius: 16px 16px 0 0;
            vertical-align: top;
            ${isSinglePage ? 'max-height: 240px;' : ''}
            aspect-ratio: 1;
            object-fit: cover;
          }
          &.entity-image {
            border-radius: 16px 16px 0 0;
          }
        }
        .quickview {
          bottom: 332px;
          box-shadow: none;
          .quickview-inputs-wrapper {
            margin-bottom: 16px;
          }
          .quote {
            font-style: normal;
          }
          .quote-circle {
            background-color: #${buttonColor};
            color: ${isDarkColor(buttonColor) ? COLORS.WHITE : COLORS.BLACK};
            top: -15px;
          }
        }
        &:hover {
          .quickview {
            bottom: 334px;
          }
        }
      }
    }


    ${
      isSinglePage
        ? `
    overflow: hidden;
    `
        : ''
    }
  `;
};

export const RegistryTileGrid = styled.div`
  display: grid;
  grid-column-gap: ${SPACING.MD};
  grid-row-gap: ${SPACING.MD};
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  margin-top: ${SPACING.SM};
  min-height: 800px;
  padding-bottom: ${SPACING.SM};

  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    grid-column-gap: ${SPACING.MD};
    grid-row-gap: ${SPACING.MD};
    grid-template-columns: repeat(3, 1fr);
  }
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE_XS} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ConditionalRender = styled.span`
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    display: none;
  }
`;

export const RegistryTileGridSP = styled.div`
  display: grid;
  grid-column-gap: ${SPACING.MD};
  grid-row-gap: ${SPACING.MD};
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  margin-top: 60px;
  min-height: 800px;
  padding-bottom: 60px;
`;

export const ViewAllContainer = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;

  button {
    width: 300px;
  }
`;
